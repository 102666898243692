import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { noop } from "lodash";
import React from "react";

import { Checkbox } from "~src/designSystem/atoms/Checkbox";
import { ITableColumn, ITableRow } from "~src/designSystem/tables/Table/types";
import { computeTableGrid } from "~src/designSystem/tables/Table/utils/styling";
import { typedMemo } from "~src/shared/helpers/react";
import { IUseListCheckable } from "~src/shared/lists/hooks/useListCheckable";

import { RowFields } from "./RowFields";

interface IProps {
  columns: readonly ITableColumn[];
  row: ITableRow;
  checkable?: IUseListCheckable;
  isSelected: boolean;
  onClick?: (row: ITableRow) => void;
  leftPadding: number;
  sidePadding?: number;
  updateRowData: (data: unknown) => void;
}

const TableRowInner = (props: IProps) => {
  const { columns, row, checkable, isSelected, onClick, leftPadding, sidePadding, updateRowData } =
    props;

  return (
    <TableRowWrapper
      isSelected={isSelected}
      leftPadding={leftPadding}
      sidePadding={sidePadding}
      showCheckbox={checkable !== undefined}
      columns={columns}
      role={checkable !== undefined ? "button" : undefined}
      onClick={
        checkable === undefined
          ? undefined
          : () => {
              checkable.toggleSelected(row.id);
            }
      }
    >
      {checkable !== undefined && (
        <TableBoxWrapper>
          <Checkbox
            id={`row-${row.index}`}
            label=""
            checked={checkable.isSelected(row.id)}
            onChange={noop}
          />
        </TableBoxWrapper>
      )}
      <RowFields updateRowData={updateRowData} columns={columns} onClick={onClick} row={row} />
    </TableRowWrapper>
  );
};

export const TableRow = typedMemo(TableRowInner, (prevProps, nextProps) => {
  return (
    prevProps.columns === nextProps.columns &&
    prevProps.row.id === nextProps.row.id &&
    prevProps.checkable === nextProps.checkable
  );
});

interface IWrapper {
  showCheckbox: boolean;
  columns: readonly ITableColumn[];
  isSelected: boolean;
  leftPadding: number;
  sidePadding?: number;
}

const TableRowWrapper = styled.div<IWrapper>`
  align-items: center;
  height: 100%;

  ${(props) =>
    computeTableGrid(props.showCheckbox, props.columns, props.leftPadding, 0, props.sidePadding)}

  border-bottom: 1px solid ${(props) => props.theme.components.Table.Row.border};

  &:hover {
    ${(props) =>
      props.onClick !== undefined &&
      css`
        background-color: ${props.theme.components.Table.Row.background.hover};
        cursor: pointer;
      `}
  }
`;

const TableBoxWrapper = styled.div`
  padding-right: 14px;
`;
